export const environment = {
    production: true,
    version: "",
    LOG_ROCKET: {
      AppId: "",
    },
    SERVER_CONFIG: {
      hostname: "api.professor-frink.getvish.io",
      port: "443",
      protocol: "wss",
      httpProtocol: "https",
    },
    APP_CONFIG: {
      baseUrl: "https://app.professor-frink.getvish.io",
      apiVersion: "V2",
      v1Url: "https://v1.professor-frink.getvish.io",
      v2Url: "https://app.professor-frink.getvish.io",
    },
    booker: {
      authEntryPoint: "https://integrations.getvish.com",
      baseUrl: "https://identity.booker.com",
      clientId: "qG9ixMKJ6UFi",
      redirectUrl: "https://integrations.getvish.com/booker/auth/callback",
    },
  };